:local(#feed-configs) {
  .refreshScheduleButton {
    .is-loading {
      animation: rotating 2s linear infinite;
    }
  }
}

:local(.report-table) {
  &.ReactTable .rt-tbody .rt-td {
    line-height: 10px;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:local(.study-list) {
  &.MuiList-root {
    margin-top: 40px;
    margin-right: 40px;
    width: calc(100% - 40px);
    background-color: #eeeeee;
    a {
      color: inherit;
      display: block;
      width: 100%;
    }
  }
}

:local(.refresh-dialog) {
}
