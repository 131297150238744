:local(.stage) {
  padding: 0px 20px;
  overflow: auto;
  width: 100%;
  height: 100vh;
  box-shadow: inset 10px 0px 5px -5px rgba(0, 0, 0, 0.2);
  > div {
    padding: 40px 0 0;
  }
  h1 {
    font-size: 19px;
    font-weight: 500;
    color: rgb(120, 129, 149);
    width: 100%;
    margin-right: 17px;
    margin-bottom: 30px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    white-space: nowrap;
    &:before {
      content: "";
      width: 5px;
      height: 40px;
      background-color: rgb(228, 230, 233);
      display: flex;
      margin: 0px 15px 0px 0px;
    }
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: rgb(228, 230, 233);
      display: flex;
      margin: 0px 0px 0px 15px;
    }
  }
}
