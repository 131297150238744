:local(#form) {
  height: 100%;
  width: 100%;
  padding: 0 0 50px 0;
  overflow: auto;
}
:local(.action-bar) {
  margin-bottom: 20px;
  > *:not(:first-child) {
    margin-left: 20px;
  }
}

.has-errors {
  color: red;
}

.rt-td {
  text-align: center;
}
