$sidebar: (
  width: 220px,
);

:local(#sidebar) {
  position: relative;
  flex: 0 0 map-get($sidebar, "width");
  max-width: map-get($sidebar, "width");
  min-width: map-get($sidebar, "width");
  width: map-get($sidebar, "width");
  background: #ff3926;
  height: 100%;

  :local(#children) {
    height: 100%;
    margin-top: -0.1px;
    padding-top: 0.1px;
  }

  :local(#header) {
    height: 90px;
    text-align: center;
    background: rgba(0, 0, 0, 0.18);
    margin: 0px;
    padding: 20px 10px;
    overflow: hidden;
    border-radius: 0px;
    img {
      height: 100%;
      width: auto;
    }
  }

  :local(#body) {
    nav {
      padding: 35px 0;
      background: transparent;
      box-sizing: border-box;
      font-size: 14px;
      margin: 0;
      line-height: 0;
      outline: none;
      zoom: 1;

      :local(.menuItem) {
        width: 100%;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding: 0px 24px;
        margin: 0px;
        height: 40px;
        line-height: 40px;
        overflow: hidden;
        font-size: 14px;
        position: relative;
        white-space: nowrap;
        cursor: pointer;
        font-weight: 500;
        text-decoration: none;
        display: block;
        color: hsla(0, 0%, 100%, 0.85);
        transition: color 0.3s ease;
        span {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          font-size: 14px;
          color: currentColor;
          transform: translate3d(0, 0, 0);
          transition: transform 0.3s ease 0.1s;
        }
        i {
          font-size: 19px;
          color: currentColor;
          width: 18px;
          margin: 0px 30px 0px 0px;
          transition: transform 0.3s ease;
          transform: translate3d(0, 0, 0);
        }
        &:local(.is-active) {
          font-weight: bold;
          color: white;
          background-color: rgba(0, 0, 0, 0.1);
        }
        &:hover,
        &:focus {
          :local(.menuItem) {
            color: white;
          }
          i,
          span {
            transform: translate3d(2px, 0, 0);
          }
        }
      }
    }
  }
}
