#studies {
}
:local(.status) {
  width: 130px;
  svg {
    margin-left: 5px;
  }
  &.is-draft {
    color: orange;
  }
  &.is-demo {
    color: red;
  }
  &.is-live {
    color: green;
  }
}

:local(.url-generator) {
  .toolbar {
    padding: 20px 24px;
  }
}

.empty-action {
  display: inline-flex;
  position: relative;
  width: 40px;
  margin-left: 15px;
}

:local(.action-button) {
  margin: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  justify-content: center;
  margin-left: 15px;
  display: inline-flex;
}

.button-generate-url {
  margin-top: -15px !important;
}
